var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-partner')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"podcast",attrs:{"color":"#556ee6","enctype":"multipart/form-data","finishButtonText":this.$route.name == 'add-partner' ? 'Save Data' : 'Update Data',"back-button-text":"Go Back!","next-button-text":"Go Next!"},on:{"on-complete":_vm.submitData}},[_c('tab-content',{attrs:{"title":"Partner Details","icon":"mdi mdi-account-details","before-change":() => _vm.validateFormOne()}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Partner Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.title.$invalid,
                    },attrs:{"id":"input-1","placeholder":"Enter Partner Name"},on:{"keyup":function($event){$event.preventDefault();return _vm.slugify.apply(null, arguments)}},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Partner Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-2"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Link Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.link_name.$invalid,
                  },attrs:{"id":"input-2","placeholder":"Enter Link Name"},model:{value:(_vm.form.link_name),callback:function ($$v) {_vm.$set(_vm.form, "link_name", $$v)},expression:"form.link_name"}}),(_vm.submitted && !_vm.$v.form.link_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Link Name is required. ")]):_vm._e()],1)],1),_c('b-form-group',{staticClass:"position-relative",attrs:{"id":"input-group-3","label":"Tell Us More About Partner","label-for":"input-3"}},[_c('ckeditor',{attrs:{"maxlength":400},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"text-right"},[(_vm.form.description)?_c('p',{staticClass:"badge position-absolute",class:{
                    'badge-success': _vm.form.description.length !== 400,
                    'badge-danger': _vm.form.description.length === 400
                  },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.description.length)+" out of 400 chars. ")]):_vm._e()])],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-4","label":"Website Banner [Upload Max File Size : 2MB]","label-for":"input-4"}},[_c('b-form-file',{ref:"website_banner_image",attrs:{"id":"input-4","accept":"image/*","placeholder":"Choose a file or drop it here... "},on:{"change":function($event){return _vm.readFile($event, 'website_banner_image')}}}),(
                    _vm.$route.name == 'edit-partner' &&
                    _vm.edit.website_banner_image_url
                  )?[_c('img',{ref:"website_banner_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.website_banner_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.website_banner_image_url)?[_c('img',{ref:"website_banner_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.website_banner_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-5","label":"App Banner [Upload Max File Size : 2MB]","label-for":"input-5"}},[_c('b-form-file',{ref:"app_banner_image",attrs:{"id":"input-5","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'app_banner_image')}}}),(
                    _vm.$route.name == 'edit-partner' && _vm.edit.app_banner_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.app_banner_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.app_banner_image_url)?[_c('img',{ref:"app_banner_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.app_banner_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-4",attrs:{"id":"input-group-9","label":"App Logo [Upload Max File Size : 2MB]","label-for":"input-9"}},[_c('b-form-file',{ref:"app_logo_image",attrs:{"id":"input-9","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'app_logo_image')}}}),(
                    _vm.$route.name == 'edit-partner' && _vm.edit.app_logo_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.app_logo_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.app_logo_image_url)?[_c('img',{ref:"app_logo_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.app_logo_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-4",attrs:{"id":"input-group-10","label":"Open Page Register [Upload Max File Size : 2MB]","label-for":"input-10"}},[_c('b-form-file',{ref:"open_page_register_image",attrs:{"id":"input-10","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'open_page_register_image')}}}),(
                    _vm.$route.name == 'edit-partner' &&
                    _vm.edit.open_page_register_image_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.open_page_register_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.open_page_register_image_url)?[_c('img',{ref:"open_page_register_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.open_page_register_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-4",attrs:{"id":"input-group-11","label":"Open Page Banner [Upload Max File Size : 2MB]","label-for":"input-11"}},[_c('b-form-file',{ref:"open_page_banner_img",attrs:{"id":"input-11","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'open_page_banner_img')}}}),(
                    _vm.$route.name == 'edit-partner' &&
                    _vm.edit.open_page_banner_img_url
                  )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.open_page_banner_img_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.open_page_banner_img_url)?[_c('img',{ref:"open_page_banner_img_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.open_page_banner_img_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1)],1),_c('tab-content',{attrs:{"title":"Add Tags","icon":"fa fa-tag"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-6","label":"Meta Title","label-for":"input-6"}},[_c('b-form-input',{attrs:{"id":"input-6","maxlength":190,"placeholder":"Enter Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_title)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_title.length !== 190,
                      'badge-danger': _vm.form.meta_title.length === 190
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_title.length)+" out of 190 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-8","label":"Meta Keywords","label-for":"input-8"}},[_c('b-form-input',{attrs:{"id":"input-8","maxlength":250,"placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_keywords)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_keywords.length !== 250,
                      'badge-danger': _vm.form.meta_keywords.length === 250
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_keywords.length)+" out of 250 chars. ")]):_vm._e()])],1)],1),_c('b-form-group',{staticClass:"mb-4 position-relative",attrs:{"id":"input-group-9","label":"Meta Description","label-for":"input-9"}},[_c('b-form-textarea',{attrs:{"id":"input-9","placeholder":"Enter Meta Description","rows":"3","maxlength":250},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_description)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_description.length !== 250,
                      'badge-danger': _vm.form.meta_description.length === 250
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_description.length)+" out of 250 chars. ")]):_vm._e()])],1),(_vm.$route.name == 'edit-partner')?[_c('h6',[_vm._v("Tabs Listed:")]),_vm._l((_vm.forum_tabs),function(data,i){return _c('div',{key:i},[_c('label',[_vm._v("Label for "+_vm._s(data.label)+" :")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label-for":"case"}},[_c('b-form-input',{attrs:{"id":"case","type":"number","min":"0","placeholder":"Index"},model:{value:(data.index),callback:function ($$v) {_vm.$set(data, "index", $$v)},expression:"data.index"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Enter Label"},model:{value:(data.label),callback:function ($$v) {_vm.$set(data, "label", $$v)},expression:"data.label"}})],1)],1),_c('div',{staticClass:"col-md-4",staticStyle:{"display":"flex","align-items":"start","padding-top":"10px"}},[_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('b-form-checkbox',{model:{value:(data.active),callback:function ($$v) {_vm.$set(data, "active", $$v)},expression:"data.active"}},[_vm._v("Active")])],1)])])])})]:_vm._e(),_c('b-form-group',{attrs:{"id":"input-group-13"}},[_c('b-form-checkbox',{model:{value:(_vm.form.is_visible_in_home),callback:function ($$v) {_vm.$set(_vm.form, "is_visible_in_home", $$v)},expression:"form.is_visible_in_home"}},[_vm._v("Is Visible in Home")])],1)],2)],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }